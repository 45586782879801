import React from 'react';
import Layout from '../templates/Layout';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import FAQList from '../components/FAQList';
import { faqSchema } from '../components/faqSchema';
import HeroDiagonal from '../components/HeroDiagonal';
import SimpleGalleryProducts from '../components/SimpleGalleryProducts';
import SimpleGalleryGoverment from '../components/SimpleGalleryGoverment';
import { useGalleryQuery } from '../hooks/useGalleryQuery';
import LightBoxGallery from '../components/LightBoxGallery';
import Payments from '../components/Payments';
import SkewBanner from '../components/SkewBanner';
import ServiceListResidential from '../components/ServicesListResidential';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CallTracking } from '../utilities/EventTracking';

const IndexPage = () => {
  const { residencial } = useGalleryQuery();
  const resi = residencial.edges.map(({ node }) => node.childImageSharp);

  return (
    <Layout>
      <SEO
        pageTitle="Sellado de techo residencial en Puerto Rico"
        pageDescription="V&A Roofing, Inc. le ofrece el mejor precio con hasta 12 años de garantía. Estimados Gratis."
        pageSchema={faqSchema}
      />
      <HeroDiagonal />

      <ServiceListResidential />

      <SkewBanner title="Nuestro compromiso">
        Hasta 12 años de garantía
      </SkewBanner>

      <FAQList />

      <SkewBanner>
        <Link to="/cotizacion">
          Estimados gratis{' '}
          <span className="hover:translate-x-3">
            <FontAwesomeIcon icon="arrow-right" />
          </span>
        </Link>
      </SkewBanner>

      <LightBoxGallery
        galleryTitle="Residencial"
        galleryDescription="Trabajo con calidad Danosa utilizando una membrana con terminación de aluminio."
        images={resi}
      />

      <SkewBanner title="Llama ahora">
        <a
          className="md:cursor-not-allowed"
          href="tel:17875976536"
          onClick={CallTracking}
        >
          <FontAwesomeIcon icon="phone-alt" className="mr-2" />
          787-597-6536
        </a>
      </SkewBanner>

      <SimpleGalleryProducts />

      <SimpleGalleryGoverment />

      <Payments />
    </Layout>
  );
};

export default IndexPage;
