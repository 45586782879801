import React from 'react';
import FAQItem from './FAQItem';
import JSONData from '../../content/static-content/questions.json';

const FAQList = () => {
  return (
    <section className="mx-4">
      <div className="container mb-16 md:my-16" id="preguntas-frecuentes">
        <h2 className="mb-6 text-2xl font-bold text-center md:text-3xl md:mb-10">
          {JSONData.sectionTitle}
        </h2>
        <div
          className="flex flex-wrap max-w-2xl ml-auto mr-auto"
          aria-label="Accordion Control Group Buttons"
        >
          {JSONData.frequentlyAsked.map((item, index) => {
            return (
              <FAQItem key={index} title={item.title} content={item.content} />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQList;
