import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FAQItem = ({ title, content }) => {
  return (
    <Accordion
      allowZeroExpanded="true"
      className="flex flex-col w-full border-t py-2 last:border-b last:mb-0"
    >
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className="flex items-center px-6 py-1 md:py-4 text-gray-800 focus:outline-none focus:shadow-outline cursor-pointer">
            <AccordionItemState>
              {({ expanded }) =>
                expanded ? (
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="mr-4 transform duration-300 rotate-90"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="mr-4 transform duration-100"
                  />
                )
              }
            </AccordionItemState>
            <h3 className="mr-3 text-base text-left font-bold">{title}</h3>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="fadein leading-relaxed pt-4 px-4 pb-8 ml-8">
          {content}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FAQItem;
