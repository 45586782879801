import React from 'react';

const SkewBanner = ({ title, children }) => {
  return (
    <section className="mb-16">
      <div className="diagonal-box before:bg-red-gradient">
        <div className="container relative p-5 text-white sm:max-w-4xl md:max-w-5xl">
          <p className="mb-2 font-semibold tracking-tight text-center uppercase lg:text-2xl md:mb-4">
            {title}
          </p>
          <h2 className="text-3xl font-bold tracking-wide text-center uppercase lg:text-6xl">
            {children}
          </h2>
        </div>
      </div>
    </section>
  );
};

export default SkewBanner;
