import JSONData from '../../content/static-content/questions.json';

export const faqSchema = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  'mainEntity': JSONData.frequentlyAsked.map(faq => {
    return JSON.parse(
      `{"@type":"Question","name":"${faq.title}","acceptedAnswer":{"@type":"Answer","text":"${faq.content}"}}`
    );
  }),
};
